<template>
    <card-list-item
        chevron
        @click="$emit('click')"
        :class="{'active': active}"
    >
        <template v-slot:icon>
            <i class="far fa-fw" :class="[icon, {'fas': active, 'far': !active}]"></i>
        </template>
        <template v-slot:content>
            <div>{{title}}</div>
        </template>
        <template v-slot:badge>
            <span class="t-badge" v-if="badge">{{badge}}</span>
            <b-tag type="is-warning" class="ml-3" v-if="Number.isInteger(tasks) && tasks > 0"><i class="fas fa-tasks mr-1"></i>{{tasks}}</b-tag>
            <b-tag type="is-warning" class="ml-3" v-if="tasks === true"><i class="fas fa-tasks"></i></b-tag>
        </template>
    </card-list-item>
</template>

<script>
    import CardListItem from "@/TIER/components/CardListItem";
    export default {
        name: "TaskNavigationListItem",
        components: {CardListItem},
        props: {
            active: {
                type: Boolean,
                default: false
            },
            tasks: {
                type: [Number, Boolean],
                default: 0
            },
            icon: {
                type: String,
                required: true
            },
            badge: [String, Number],
            title: {
                type: String,
                required: true
            }
        }
    };
</script>

<style lang="scss" scoped>
    li {
        color: darken($t-primary, 18);
        padding: 0.7rem 0 0.45rem;
        font-size: .9rem;
        font-weight: 700;
    }

    .t-badge {
        background-color: $t-primary;
        border-radius: 4px;
        color: white;
        padding: 2px 7px 0;
        font-size: .7rem;
    }
</style>
